import React from 'react';
import { useNavigate } from 'react-router-dom';


const EnterprisePage = () => {
    const navigate = useNavigate();
  return (
    <div className="container mx-auto p-4 mt-10">
      {/* Header Section */}
      <div className="text-center">
        <h1 className="text-3xl font-bold text-custom-blue">
          Designed for companies in the era of digital complexity
        </h1>
        <p className="text-center text-[1.25rem] leading-6 mx-auto py-7 text-gray-600">
          The evolution of the business environment never stops. We create the platform you need to become a leader in your industry.
        </p>
        {/*<button className="px-6 py-2 bg-custom-blue text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" onClick={() => navigate("/demo")}>
                      Request a Demo
                    </button>*/}
      </div>

      {/* Mission Statement Section */}
      <div className="mt-20 mx-auto flex justify-center">
        <div className="bg-custom-blue text-white p-10 rounded-lg w-full md:w-3/4">
          <h2 className="text-2xl font-bold text-center mb-4">
            Our mission
          </h2>
          <p className="text-lg text-center">
            "We deliberately strive to generate the greatest positive impact for society through the implementation of technology and data management."
          </p>
        </div>
      </div>
      {/* Values Section */}
      <div className="mt-20">
        <h2 className="text-3xl font-bold text-custom-blue mb-4 text-center">
          Values of Strathens
        </h2>
        <p className="text-center text-gray-600 mb-12 p-10 ">
          The values that represent us are the foundations that distinguish the development of our culture as a company, as well as guide the decisions we make daily for the benefit of our users.
        </p>

        {/* Values List */}
        <div className="space-y-10 p-10 ml-[2rem]">
          <div className="flex items-center">
            <div className="text-custom-blue text-2xl p-2">•</div>
            <div>
              <h3 className="text-xl font-semibold text-custom-blue">Serve our clients</h3>
              <p className="text-gray-600">
                Our efforts focus on contributing to the growth and development of our clients.
              </p>
            </div>
          </div>

          <div className="flex items-center">
            <div className="text-custom-blue text-2xl p-2">•</div>
            <div>
              <h3 className="text-xl font-semibold text-custom-blue ">Technological adoption</h3>
              <p className="text-gray-600">
                Constant technological implementation focused on the greatest benefit for society as a whole.
              </p>
            </div>
          </div>

          <div className="flex items-center">
            <div className="text-custom-blue text-2xl p-2">•</div>
            <div>
              <h3 className="text-xl font-semibold text-custom-blue">Teamwork</h3>
              <p className="text-gray-600">
                Teamwork nurtures the foundation of Strathens' business development.
              </p>
            </div>
          </div>

          <div className="flex items-center">
            <div className="text-custom-blue text-2xl p-2">•</div>
            <div>
              <h3 className="text-xl font-semibold text-custom-blue">A brand with impact</h3>
              <p className="text-gray-600">
                Strathens, as a brand, focuses on positively impacting people's daily lives and their development as professionals.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Call-to-Action Section */}
      <div className="mt-20 text-center">
        <h2 className="text-2xl font-bold text-custom-blue">
          Add maximum efficiency and productivity to your company today
        </h2>
        <button className="mt-6 bg-custom-blue text-white px-5 py-2 rounded transform hover:-translate-y-1 transition duration-400" onClick={() => navigate('/contact')}>
          Contact us
        </button>
      </div>
    </div>
  );
};

export default EnterprisePage;