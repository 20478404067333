import { Compare } from "../components/ui/compare";
import { HoverEffect } from "../components/ui/card-hover-effect";
import { Timeline } from "../components/ui/timeline";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { WobbleCard } from "../components/ui/wobble-card";
import ipad from "../assets/images/ipad-mini.png";
import grafic from "../assets/images/grafic.png"
import excel from "../assets/images/excel.png"
import sales from "../assets/images/sales.png"
import planning from "../assets/images/planning.svg"

export default function Platform(){

const [position, setPosition] = useState(50);
  const navigate = useNavigate();
  const handleDrag = (e) => {
    const containerWidth = e.target.parentElement.clientWidth;
    const newPosition = (e.clientX / containerWidth) * 100;
    setPosition(newPosition);
  };
  const data = [
    {
      title: "A source of certainty",
      content: (
        <div>
          
          <div className="mb-8">
          </div>
          <div className="grid grid-cols-1 gap-4">
           <img
            src={planning}
            alt="grafic"
            className="w-full align-center object-contain"
          />
          </div>
          <p
            className="text-neutral-800 dark:text-neutral-200 text-base md:text-lg font-normal mb-4 sm:px-4 leading-relaxed sm:leading-normal"
            style={{ fontSize: "1rem" }}
          >
            Make decisions at the speed of market changes.
Update your strategy in real time.
          </p>
        </div>
      ),
    },
    {
      title: "A vision of the present, past and future of your organization",
      content: (
        <div>
          <div className="grid grid-cols-1 gap-4">
            <Compare
            firstImage={excel}
            secondImage= {sales}
            firstImageClassName="object-cover object-left-top"
            secondImageClassname="object-cover object-left-top"
            className="h-[250px] w-[250px] md:h-[500px] md:w-[500px]"
            slideMode="hover"
          />
          </div>
          <p
            className="text-neutral-800 dark:text-neutral-200 text-base md:text-lg font-normal mb-4 sm:px-4 leading-relaxed sm:leading-normal"
            style={{ fontSize: "1rem" }}
          >
            Understand and compare your results with your planning and historical data. All in one place.
          </p>
        </div>
      ),
    },
    {
      title: "Extract the information you need",
      content: (
        <div>
          <div className="grid grid-cols-1 gap-4">
          <img
            src={grafic}
            alt="data"
            className="w-full align-center object-contain"
          />
          </div>
          <p
            className="text-neutral-800 dark:text-neutral-200 text-base md:text-lg font-normal mb-4 sm:px-4 leading-relaxed sm:leading-normal"
            style={{ fontSize: "1rem" }}
          >
            Create and customize interactive dashboards in a simple way to
            visualize your progress
          </p>
        </div>
      ),
    },
    
  ];
  return (
    <div className="container mx-auto p-10">
      {/* Sección superior */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12 p-10">
        <div className="text-left">
          <h1 className="text-4xl font-bold text-custom-blue">
            Analyze every piece of data, every detail, and every trend
          </h1>
          <p className="text-left text-[1.25rem] leading-6 py-7 text-gray-600">
            Transform your data into valuable insights to guide your organization.
          </p>
          {/*<button className="px-6 py-2 bg-custom-blue text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" onClick={() => navigate("/demo")}>
                      Request a Demo
                    </button>*/}
        </div>
        <div className="flex p-4 rounded-3xl  px-4">
          <img
            src={ipad}
            alt="ipad"
            className="w-full align-center object-contain"
          />
        </div>
      </div>

      {/* Sección central */}
      <div className="text-center mb-12">
        <h2 className="text-3xl font-semibold text-custom-blue">
          Synchronize your planning with the analysis of results to make informed decisions
        </h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 max-w-7xl mx-auto w-full p-10">
        <WobbleCard
        containerClassName="col-span-1 lg:col-span-2 h-full bg-custom-blue min-h-[500px] lg:min-h-[300px]"
        className=""
      >
        <div className="max-w-xs">
          <h2 className="text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">
            Integrate all your platforms
          </h2>
          <p className="mt-4 text-left  text-base/6 text-neutral-200">
            Extract independent information from your platforms to filter and analyze it jointly
          </p>
        </div>
      </WobbleCard>
      <WobbleCard containerClassName="col-span-1 min-h-[300px] bg-custom-blue">
        <h2 className="max-w-80  text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">
          Detailed analysis of the results of your marketing and sales performance
        </h2>
      </WobbleCard>
      <WobbleCard containerClassName="col-span-1 min-h-[300px] bg-custom-blue">
        <h2 className="max-w-80  text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">
          Artificial intelligence to extract information not visible to humans
        </h2>
      </WobbleCard>
      <WobbleCard
        containerClassName="col-span-1 lg:col-span-2 h-full bg-custom-blue min-h-[500px] lg:min-h-[300px]"
        className="flex flex-col lg:flex-row justify-between"
      >
        <div className="max-w-xs">
          <h2 className="text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">
            A source of certainty
          </h2>
          <p className="mt-4 text-left  text-base/6 text-neutral-200">
            Make decisions at the speed of market changes. Update your strategy in real time
          </p>
        </div>
      </WobbleCard>
      </div>
      <div className="text-center mb-2" style={{paddingTop:'5rem'}}>
        <h2 className="text-3xl font-semibold text-custom-blue">
          Establish a culture of data-driven decision making
        </h2>
      </div>
      <div className="w-full text-center justify-center p-10">
        <Timeline data={data} />
      </div>
      {/* Sección de íconos */}
      <div className="text-center mb-12">
        <h2 className="text-3xl font-semibold text-custom-blue">
          Why Strathens for your business analytics?
        </h2>
      </div>
      <div className="max-w-5xl mx-auto px-8">
        <HoverEffect items={projects} />
      </div>
      <div className="text-center">
        {/*<button className="px-6 py-2 bg-custom-blue text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" onClick={() => navigate("/demo")}>
                      Request a Demo
                    </button>*/}
      </div>
    </div>
  );
};
export const projects = [
  {
    title: "All the information you need",
    description: "Centralize data in a single source of certainty",
  },
  {
    title: "Analyze and draw conclusions",
    description: "Make decisions and adapt to market changes",
  },
  {
    title: "Develop your strategy for your organization",
    description:
      "Efficiency and automation, focus on what helps your company move forward",
  },
  {
    title: "Unlock the value of data to your advantage",
    description:
      "Efficiency and automation, focus on what helps your company move forward",
  },
];
