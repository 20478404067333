import React, { useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import {
  HoveredLink,
  Menu,
  MenuItem,
  ProductItem,
} from '../components/ui/navbar-menu';
import { cn } from 'src/lib/utils';
import logo from '../assets/images/logo_strathens2.svg';
import reporting from '../assets/images/reportingpage.png'
import Card from '../components/socialbutton2';
import Button from '../components/socialbutton2';



export default function RootLayout() {
  return (
    <>
      <header className="relative w-full flex items-center justify-center">
        <Navbar />
      </header>
      <main style={{ paddingTop: '130px' }}>
        <Outlet />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}
function Navbar({ className }) {
  const [active, setActive] = useState(null);
  return (
    <div
      className={cn('fixed top-2 inset-x-0 max-w-2xl mx-auto z-50', className)}
    >
      <Menu setActive={setActive}>
        <MenuItem
          setActive={setActive}
          active={active}
          item={
            <Link to="/">
              <img
                src={logo}
                alt="Logo"
                className="inline-block h-8 w-auto align-middle mr-6"
              />
            </Link>
          }
        />
        <MenuItem setActive={setActive} active={active} item="Platform">
          <div className="flex flex-col space-y-4 text-sm">
            <HoveredLink to="/Platform">Features</HoveredLink>
          </div>
        </MenuItem>
        {/*<MenuItem setActive={setActive} active={active} item="Platform">
          <div className="  text-sm grid grid-cols-2 gap-10 p-4">
            <ProductItem
              title="Planning"
              to="/planning"
              src="https://assets.aceternity.com/demos/tailwindmasterkit.webp"
              description="Prepare for tech interviews like never before."
            />
            <ProductItem
              title="Analytics"
              to="/analisis"
              src="https://assets.aceternity.com/demos/tailwindmasterkit.webp"
              description="Production ready Tailwind css components for your next project"
            />
          </div>
        </MenuItem>*/}
        <MenuItem setActive={setActive} active={active} item="Enterprise">
          <div className="flex flex-col space-y-4 text-sm">
            <HoveredLink to="/aboutus">About us</HoveredLink>
            <HoveredLink to="/contact">Contact us</HoveredLink>
          </div>
        </MenuItem>
       { /*<MenuItem setActive={setActive} active={active} item="Pricing">
          <div className="flex flex-col space-y-4 text-sm">
            <HoveredLink href="/hobby">Hobby</HoveredLink>
            <HoveredLink href="/individual">Individual</HoveredLink>
            <HoveredLink href="/team">Team</HoveredLink>
            <HoveredLink href="/enterprise">Enterprise</HoveredLink>
          </div>
        </MenuItem>*/}
      </Menu>
    </div>
  );
}

export function Footer({ className }) {
  return (
    <>
      <div
        className={cn(
          'mx-auto max-w-7xl px-6 py-12 flex items-center justify-between lg:px-8',
          className
        )}
      >
        {/* Left side: Logo and Rights Reserved text */}
        <div className="flex flex-col items-start">
          <div style={{ width: '10rem', height: '10rem' }}>
            <img src={logo} alt='logo' className="object-contain w-full h-full" />
          </div>
          <p className="text-left text-xs leading-5 text-gray-500">
            &copy; 2024 Strathens. All rights reserved. Icons by
            <Link className='text-left text-xs leading-5 text-gray-500' to="https://icons8.com/">Icons8</Link>
          </p>
          <Link className='text-left text-xs leading-5 text-gray-500' to="/privacy-policy">Política de Privacidad</Link>
        </div>

        {/* Right side: Social Buttons */}
        <div className="flex">
          <Button/>
        </div>
      </div>
    </>
  );
}
