import React from 'react';
import { useNavigate } from 'react-router-dom';

const ContactPage = () => {
    const navigate = useNavigate();
  return (
    <div className="container mx-auto p-4">
      {/* Header Section */}
      <div className="text-center mb-10">
        <h1 className="text-3xl font-bold text-custom-blue">
          We would love to hear from you
        </h1>
        <p className="text-gray-600 mt-2">
          Send us a message and we'll get back to you within the next 24 hours.
        </p>
      </div>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row justify-between">
        {/* Left Section */}
        <div className="md:w-1/3" style={{marginRight: '1rem'}}>
          <p className="text-custom-blue mb-6">
            If you have any other questions or concerns about our company, please let us know in the form.
          </p>
          <div className="border-t border-gray-300 mb-6"></div>
          <p className="text-custom-blue mb-4">
            Do you want to experience Strathens in your organization?
          </p>
          {/*<button className="px-6 py-2 bg-custom-blue text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" onClick={() => navigate("/demo")}>
                      Request a Demo
                    </button>*/}
        </div>

        {/* Right Section - Contact Form */}
        <div className="md:w-2/3 mt-10 md:mt-0">
          <form name="contact" action='/contact' method="post">
                  {/* Hidden input for Netlify form name */}
                  <input type="hidden" name="form-name" value="contact" />
                  <input type="hidden" name="bot-field" />

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <input
                      type="text"
                      name="nombre"
                      placeholder="Name"
                      className="form-control p-2 border border-gray-300 rounded-md"
                      required
                    />
                    <input
                      type="text"
                      name="apellido"
                      placeholder="Surname"
                      className="form-control p-2 border border-gray-300 rounded-md"
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Business email address"
                      className="form-control p-2 border border-gray-300 rounded-md md:col-span-2"
                      required
                    />
                    <input
                      type="text"
                      name="posicion"
                      placeholder="Job position"
                      className="form-control p-2 border border-gray-300 rounded-md"
                    />
                    <input
                      type="text"
                      name="empresa"
                      placeholder="Company name"
                      className="form-control p-2 border border-gray-300 rounded-md"
                    />
                    <input
                      type="tel"
                      name="telefono"
                      placeholder="Phone (include country code)"
                      className="form-control p-2 border border-gray-300 rounded-md md:col-span-2"
                    />
                    <textarea
                      name="mensaje"
                      placeholder="Please write what you wish to ask us"
                      className="form-control p-2 border border-gray-300 rounded-md md:col-span-2"
                      rows="4"
                      required
                    ></textarea>
                  </div>

                  <button
                    type="submit"
                    className="px-6 py-2 bg-custom-blue text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400 mt-4"
                  >
                    Send
                  </button>
</form>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;