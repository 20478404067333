
import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../components/ui/custom-card"
import ia from "../assets/images/3d-isometric-lettering-artificial-intelligence-with-robot-and-hand-holding-the-brain-text.gif"
import analytics from "../assets/images/analyticsmov.gif"
import planning from "../assets/images/strat.gif"
// Sample data for the cards
const items = [
  {
    title: "Artificial Intelligence",
    description: "Unlock data's potential with AI insights",
    content: "Leverage AI to analyze your company's data, gain deep insights, and make informed decisions. Understand your current situation and project future outcomes, all from a single platform. Drive smarter strategies with real-time analysis and forecasting.",
    image: ia,
    isGif: true,
  },
  {
    title: "Planning",
    description: "Align goals, optimize resources, and drive results",
    content: "Define short, medium, and long-term goals to align your teams. Strategically allocate resources to maximize results and ensure every action moves you closer to your objectives.",
    image: planning,
    isGif: true
  },
  {
    title: "Analytics",
    description: "Centralize data, visualize performance, and uncover insights with AI",
    content: "Unify your company’s data in one place and visualize performance in real time. Track key KPIs, understand your results, and uncover trends with AI-powered insights. Make informed decisions with deeper analysis.",
    image: analytics,
    isGif: true,
  },
]

const CustomCard = ({ item, index }) => {
  const isEven = index % 2 === 0

  return (
    <div className={`flex flex-col md:flex-row items-center ${isEven ? 'md:flex-row-reverse' : ''} mb-8`}>
      <Card className="w-full md:w-1/2 max-w-md">
        <CardHeader>
          <CardTitle>
            <h3 className="text-4xl font-bold mb-2">{item.title}</h3>
            <CardDescription>
            <p className="text-xl text-semibold text-gray-600">{item.description}</p>
          </CardDescription>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <CardDescription>
            <p className="text-xl text-semibold text-gray-600">{item.content}</p>
          </CardDescription>
        </CardContent>
      </Card>
      <div className={`w-full md:w-1/2 mt-4 md:mt-0 ${isEven ? 'md:pr-4' : 'md:pl-4'}`}>
        <img
          src={item.image}
          alt={`${item.isGif ? 'GIF' : 'Image'} for ${item.title}`}
          className={`rounded-lg object-cover 
            ${item.isGif ? 'w-full max-w-xl sm:max-w-md md:max-w-lg lg:max-w-xl' : 'w-full max-w-xs'}`}
        />
      </div>
    </div>
  )
}

export default function ZigZag() {
  return (
    <div className="w-full max-w-4xl mx-auto px-4">
      {items.map((item, index) => (
        <CustomCard key={index} item={item} index={index} />
      ))}
    </div>
  );
}