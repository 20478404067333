import React from 'react';
import '../styles/indexpage.css';
import { StickyScroll } from '../components/ui/sticky-scroll-reveal';
import { HoverEffect } from '../components/ui/card-hover-effect';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { HeroHighlight, Highlight } from '../components/ui/hero-highlight';
import { AnimatedBeamDemo } from '../components/AnimatedBeamDemo';
import Waitlist from '../components/Waitlist';
import { Carousel, Card } from '../components/ui/apple-cards-carousel';
import iconP from "../assets/images/planning_icon.png"
import ZigZag from '../components/zigzag-carousel';

export default function IndexPage() {
  const navigate = useNavigate();
  const cards = data.map((card, index) => (
    <Card key={card.src} card={card} index={index} />
  ));

  return (
    <div className="App">
      {/* Adjusted the height and added a fade-out effect to the background */}

      <div className="text-center">
        <HeroHighlight>
          <motion.h1
            initial={{
              opacity: 0,
              y: 20,
            }}
            animate={{
              opacity: 1,
              y: [20, -5, 0],
            }}
            transition={{
              duration: 0.5,
              ease: [0.4, 0.0, 0.2, 1],
            }}
            className="text-4xl px-6 md:px-10 md:text-4xl lg:text-5xl font-bold text-neutral-700 dark:text-white max-w-3xl leading-tight lg:leading-normal text-center mx-auto mb-10"
          >
            Transform business planning and analysis. <br />
            <Highlight className="text-black dark:text-white">
              Better data, better choices.
            </Highlight>
          </motion.h1>
          <p className="text-center mt-6 relative z-20 leading-7 mx-auto py-7 text-gray-700 text-xl max-w-3xl">
            Optimization and precision for your business. Connect people with
            data and unleash the power of artificial intelligence
          </p>

          <h2 className="text-2xl font-bold text-neutral-700 justify-center">Currently Developing Version 0. Join the Waitlist Now! </h2>
          <div className="transform hover:-translate-y-1 transition duration-400 py-8 flex items-center justify-center">
            
            <Waitlist />
          </div>
        </HeroHighlight>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 items-center min-h-screen gap-8 px-8">
        <div className="max-w-lg text-center justify-center md:ml-[10rem]">
          <h2 className="text-4xl font-bold text-custom-blue justify-center">
            A digital brain designed to direct the execution and growth of your
            company
          </h2>
          <p className="text-center text-[1.25rem] leading-6 py-7 text-gray-600">
            Automation and flexibility for your organization without giving up
            your operational, strategic and financial objectives
          </p>
          {/*<button
            className="px-6 py-2 bg-custom-blue text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400"
            onClick={() => navigate('/demo')}
          >
            Request a Demo
          </button>*/}
        </div>
        <div className="flex justify-center md:w-[70%] w-full">
          <AnimatedBeamDemo />
        </div>
      </div>
    <div className="w-full h-full py-20">
      <ZigZag/>
    </div>

      <div className="max-w-5xl mx-auto px-8">
        <HoverEffect items={projects} />
      </div>
    </div>
  );
}
export const projects = [
  {
    title: 'Centralize data in a single source of certainty',
    description: '',
  },
  {
    title: 'Make decisions and adapt to market changes',
    description: '',
  },
  {
    title:
      'Efficiency and automation, focus on what helps your company move forward',
    description: '',
  },
  {
    title:
      'Boost your company with efficiency in data management and processes',
  },
];

const data = [
  {
    category: "Artificial Intelligence",
    title: "Unlock data's potential with AI insights",
    src: {iconP},
  },
  {
    category: "Planning",
    title: "Align goals, optimize resources, and drive results.",
    src: "https://images.unsplash.com/photo-1531554694128-c4c6665f59c2?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: "Define short, medium, and long-term goals to align your teams. Strategically allocate resources to maximize results and ensure every action moves you closer to your objectives.",
  },
  {
    category: "Analytics",
    title: "Centralize data, visualize performance, and uncover insights with AI.",
    src: "https://images.unsplash.com/photo-1713869791518-a770879e60dc?q=80&w=2333&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: "Centralize your company's data in one place. Visualize your performance in real time to make informed decisions. Unify your key performance KPIs and understand the why of your results. Discover deep details and trends thanks to the incorporation of artificial intelligence in your analysis.",
  },
];

